<template>
  <div>
    <h3 class="title text_navy">
      Select Accessory
    </h3>
    <label class="form__label">
      <input type="text" class="form__input"
             v-model="models.item"
             placeholder="Type To Search"
      >
    </label>

    <ul class="list">
      <li class="list__item"
          v-for="elem in items"
          :key="elem.item_id"
          @click="select(elem)"
      >
        {{elem.name}}
      </li>
    </ul>


  </div>

</template>

<script>
  import {simpleSearch} from "../../../helpers/search";

  export default {
    name: "AccessoriesItem",

    data() {
      return {
        models: {
          item: ''
        },
        items: []
      }
    },

    props: ['accessoriesItems', 'type'],

    methods: {
      select(elem) {
        this.$emit('selectItem', elem);
      },

      back(){
          this.$store.commit('orderPreview/additionalAccessory', {style: 0});
      }
    },
    watch: {
      'models.item'(n) {
        this.items = simpleSearch(n, this.accessoriesItems.data);
      },

      accessoriesItems() {
        this.items = this.accessoriesItems.data;
      },
    },

    created(){
      this.items = this.accessoriesItems.data;
    },


  }
</script>

<style scoped lang="scss">

  @import "../../../assets/scss/utils/vars";

  .form {
    &__label {

    }

    &__input {
      border: none;
      border-bottom: 2px solid $navy;
    }
  }
</style>
